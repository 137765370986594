// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("/var/www/ep-frontpage-master-1583989589/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aitken-college-js": () => import("/var/www/ep-frontpage-master-1583989589/src/pages/aitken-college.js" /* webpackChunkName: "component---src-pages-aitken-college-js" */),
  "component---src-pages-armadale-street-js": () => import("/var/www/ep-frontpage-master-1583989589/src/pages/armadale-street.js" /* webpackChunkName: "component---src-pages-armadale-street-js" */),
  "component---src-pages-contact-js": () => import("/var/www/ep-frontpage-master-1583989589/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-eskdale-road-js": () => import("/var/www/ep-frontpage-master-1583989589/src/pages/eskdale-road.js" /* webpackChunkName: "component---src-pages-eskdale-road-js" */),
  "component---src-pages-index-js": () => import("/var/www/ep-frontpage-master-1583989589/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nelson-street-js": () => import("/var/www/ep-frontpage-master-1583989589/src/pages/nelson-street.js" /* webpackChunkName: "component---src-pages-nelson-street-js" */),
  "component---src-pages-projects-js": () => import("/var/www/ep-frontpage-master-1583989589/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-russell-street-js": () => import("/var/www/ep-frontpage-master-1583989589/src/pages/russell-street.js" /* webpackChunkName: "component---src-pages-russell-street-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/var/www/ep-frontpage-master-1583989589/.cache/data.json")

